.popup-frame,
.popup-frame * {
  box-sizing: border-box;
}
.popup-frame {
  background: #ffffff;
  border-radius: 26px;
  position: relative;
}
.popup-header-frame {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.popup-header-bg {
  background: #4650a7;
  border-radius: 11px 11px 0px 0px;
  align-self: stretch;
  flex-shrink: 0;
  height: 47px;
  position: relative;
  display: flex;
  padding-left: 6%;
}
.popup-title-frame {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 1%;
}
.popup-title-text {
  color: #ffffff;
  text-align: center;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-logo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  overflow: visible;
  margin-top: 60%;
}
.popup-project-title-frame {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 2%;
}
.popup-project-title-label {
  color: var(--black-900, #000000);
  text-align: left;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  margin-left: 4%;
}
.popup-attempt-frame {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.popup-attempt-text {
  color: #4650a7;
  text-align: left;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 11px;
  font-weight: 400;
  position: relative;
}
.popup-input-frame {
  border-radius: 8px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 1px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 89%;
  margin-left: 5%;
  overflow: hidden;
}
.popup-input-text {
  color: #000000;
  text-align: center;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-create-bg-frame {
  padding: 1%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 20%;
  margin-right: 5%;
}
.popup-create-bg {
  background: #4650a7;
  border-radius: 5px;
  align-self: stretch;
  flex-shrink: 0;
  height: 42px;
  position: relative;
  box-shadow: 0px 4px 11px 0px rgba(70, 80, 167, 0.25);
}
.popup-create-btn-frame {
  padding: 8px;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.popup-create-btn-text {
  color: #ffffff;
  text-align: center;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 13px;
  line-height: 27.53px;
  font-weight: 400;
  position: relative;
  margin-left: 10%;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    z-index: 1000; /* Ensure it appears above all other content */
  }

  .popup-content {
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 239px;
    width: 80%; /* Adjust width as needed */
    max-width: 32%; /* Limit maximum width */
    box-sizing: border-box;
  }

  .footer_content{
    display: flex;
    margin-top: 3%;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    .popup-content{
        max-width: 80%;
    }
  }

  @media (min-width: 769px) and (max-width: 1600px) {
    .popup-content{
        max-width: 50%;
    }
  }