
.dont_parent {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.signup_link {
    color: #4650A7;
    cursor: pointer;
    text-decoration: none;
}
.forgot-link {
    color: #4650A7;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
.error {
    color: red;
    text-align: center;
    margin-top: 2%;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.submit-button {
    width: 100%;
    padding: 13px;
    background-color: #4650A7;
    color: white;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.password-input {
    width: 100%;
    padding: 13px 16px;
    border-radius: 8px;
    border: 1px #E7E7E7 solid;
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    opacity: 0.75;
}
.password-label {
    display: block;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.username-input {
    width: 100%;
    padding: 13px 16px;
    border-radius: 8px;
    border: 1px #E7E7E7 solid;
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    opacity: 0.75;
}

.main-div {
    width: 100%;
    height: 100vh;
    background-color: #F2F3FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.username-label {
    display: block;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.login-title {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.welcome-text {
    text-align: center;
    color: #212939;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    opacity: 0.5;
}

.form-container {
    width: 80%;
    max-width: 450px;
    height: 450px;
    border-radius: 16px;
    border: 1px rgba(205, 198, 219, 0.20) solid;
    position: relative;
    background: linear-gradient(180deg, #FBFAFB 0%, white 100%);
    box-shadow: 0px 0px 30px rgba(205, 9, 198, 218.91, 0.30);
    padding: 40px; /* default padding, will be overwritten by JS if necessary */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

/* Media query for screens with height less than 567px */
@media (max-height: 567px) {
    .form-container {
        margin-top: 35%; /* Adjust margin top */
    }
}

/* Mobile screen adjustments */
@media (max-width: 768px) {
    .form-container {
        padding: 20px;
    }
}
