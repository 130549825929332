.dashed-border-box {
    border-radius: 7px;
    border-style: dashed;
    border-color: #bdbdbd;
    border-width: 1px;
    width: 100%;
    height: 233px;
    margin-top: 1%;
    margin-left: 1%;
}

.section-title {
    color: #000000;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 22px;
    line-height: 27.53px;
    font-weight: 500;
    margin-left: 1%;
    margin-top: 2%;
}

.description-text {
    color: #4e4e4e;
    text-align: left;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 22.5px;
    font-weight: 400;
    margin-left: 1%;
    margin-top: 0.7%;
}

.small-dashed-box {
    background: #ffffff;
    border-radius: 5px;
    border-style: dashed;
    border-color: #a5a5a5;
    border-width: 1px;
    width: 245px;
    height: 49.37px;
    margin-left: 1%;
    margin-top: 2%;
    box-shadow: 0px 4px 11px 0px rgba(70, 80, 167, 0.25);
}

.content-group {
    width: 191.06px;
    height: 25.6px;
    display: flex;
    position: static;
}

.icon-frame {
    width: 19.2px;
    height: 19.2px;
    margin-left: 10%;
    margin-top: 8%;
    top: 256.74px;
    overflow: visible;
}

.cta-text {
    color: #262626;
    text-align: center;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 17px;
    line-height: 27.53px;
    font-weight: 400;
    margin-left: 1%;
    margin-top: 7%;
    width: 164.55px;
    height: 25.6px;
}

.dashed-container {
    margin-left: 1%;
    margin-top: 1%;
    padding-bottom: 15%;
}

.section-heading {
    margin-left: 1%;
    margin-top: 1%;
    margin-bottom: 1%;

    color: #000;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 154.545% */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Adds edge effect to text */
}

.row_width {
    width: 80%;
}

.card-container {
    background: #fcfcff;
    border-radius: 6px;
    border-style: solid;
    border-color: #cdcdcd;
    border-width: 0.5px;
    width: 100%;
    height: 95%;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.card-title {
    color: #000000;
    text-align: left;
    margin-left: 6%;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.8px; /* 130% */
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.icon {
    top: 23.66%;
    width: 18px;
    height: 18px;
    transform: translate(0px, -16.31px);
    overflow: visible;
    margin-right: 5%;
    margin-top: 12%;
}

.card-text {
    color: var(--text-color-1, #767676);
    text-align: left;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 22.8px;
    font-weight: 400;
    width: 90%;
    margin-left: 6%;
    margin-top: 1%;
}

.card-date {
    color: var(--text-color-1, #767676);
    text-align: left;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 20.8px;
    font-weight: 400;
    width: 71px;
    height: 13px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 6%;
    margin-top: 4%;
    margin-bottom: 5%;
}

@media screen and (max-width: 786px) {
    .dashed-border-box {
        margin-left: 3%;
        margin-top: 5%;
        width: 95%;
    }

    .section-title {
        margin-top: 3% !important;
        margin-left: 3% !important;
    }

    .description-text {
        margin-top: 3% !important;
        margin-left: 3% !important;
    }

    .small-dashed-box {
        margin-top: 8% !important;
        margin-left: 3% !important;
    }

    .dashed-container {
        margin-left: 3%;
        margin-top: 5%;
        width: 95%;
        border-style: none;
    }

    .section-heading {
        margin-left: 3% !important;
        margin-top: 1% !important;
        font-size: 18px !important;
    }

    .row_width {
        width: 100%;
    }

    .card-container {
        margin-left: 3% !important;
    }
}
