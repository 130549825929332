/* Logo section */
.signup-logo {
    display: flex;
    justify-content: flex-start;  /* Align the logo to the left */
    align-items: center;          /* Center the logo vertically */
    position: absolute;           /* Keep logo in absolute position */
    top: 3%;                       /* Adjust top position to make logo visible */
    left: 10%;                     /* Set the left margin to 10% */
    width: auto;                   /* Auto width for logo */
  }
  
  /* Logo styling */
  .signup-logo-img {
    width: auto;                  /* Maintain aspect ratio */
    max-width: 150px;             /* Set max-width for larger screens */
    max-height: 50px;             /* Ensure logo height does not exceed 50px */
  }
  
  @media (max-width: 768px) {
    .signup-logo-img {
      max-width: 120px;            /* Adjust logo size for smaller screens */
      max-height: 40px;            /* Reduce max height for smaller screens */
    }
    .signup-logo {
      top: 2%;
      left: 10%;                   /* Keep the left margin at 10% on smaller screens */
    }
  }
  
  /* Responsive adjustment for very short screens */
  @media (max-height: 567px) {
    .signup-logo {
      top: 1%;  /* Adjust the position for very short screens */
    }
  }
  