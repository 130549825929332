.rectangle-1015 {
    border-radius: 7px;
    border-style: dashed;
    border-color: #bdbdbd;
    border-width: 1px;
    height: 233px;
    margin-top: 1%;
    margin-left:1%;
  }

  .listen-compare-and-grow {
    color: #000000;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 22px;
    line-height: 27.53px;
    font-weight: 500;
    margin-left: 1%;
    margin-top: 2%;
  }

  .lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-donec-non-neque-non-nibh-pretium-gravida-aliquam-consequat {
    color: #4e4e4e;
    text-align: left;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 22.5px;
    font-weight: 400;
    margin-left: 1%;
    margin-top: 0.7%;
  }

  .rectangle-1014 {
    background: #ffffff;
    border-radius: 5px;
    border-style: dashed;
    border-color: #a5a5a5;
    border-width: 1px;
    width: 245px;
    height: 49.37px;
    margin-left: 1%;
    margin-top: 2%;
    box-shadow: 0px 4px 11px 0px rgba(70, 80, 167, 0.25);
  }

  .group-446 {
    width: 191.06px;
    height: 25.6px;
    display: flex;
    position: static;
  }
  .frame3 {
    width: 19.2px;
    height: 19.2px;
    margin-left: 10%;
    margin-top: 8%;
    top: 256.74px;
    overflow: visible;
    cursor: pointer;
  }
  .start-a-new-project {
    color: #262626;
    text-align: center;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 17px;
    line-height: 27.53px;
    font-weight: 400;
    margin-left: 1%;
    margin-top: 7%;
    width: 164.55px;
    height: 25.6px;
    cursor: pointer;
  }
  .rectangle-1016 {
    border-radius: 7px;
    border-style: dashed;
    border-color: #bdbdbd;
    border-width: 1px;
    margin-left:1%;
    margin-top: 1%;
    padding-bottom: 15%;
  
  }
  .recent-projects {
    color: #000000;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 22px;
    line-height: 27.53px;
    font-weight: 500;
    margin-left: 1%;
    margin-top:1%;
    margin-bottom: 1%;
  }
  .row_width{
    width:80%;
  }
  .frame-13 {
    background: #fcfcff;
    border-radius: 6px;
    border-style: solid;
    border-color: #cdcdcd;
    border-width: 0.5px;
    width: 100%;
    height: 95%;
    margin-left:5%;
    margin-top:2%;
    margin-bottom: 2%;
  }
  .poems {
    color: #000000;
    text-align: left;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 16px;
    line-height: 20.8px;
    font-weight: 500;
    left: calc(50% - 123px);
    top: 16px;
    width: 58px;
    height: 21px;
    margin-left: 6%;
    margin-top: 5%;
  }
  .poem_flex{
    display: flex;
    align-items: center; /* Align items vertically at the center */
    justify-content: space-between; /* Space out items evenly */
    gap: 10px; 
  }
  .vector {
    height: 7.64%;
    top: 23.66%;
    transform: translate(0px, -16.31px);
    overflow: visible;
    margin-right: 5%;
    margin-top: 12%;
  }
  .little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind {
    color: var(--text-color-1, #767676);
    text-align: left;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 22.8px;
    font-weight: 400;
    left: calc(50% - 123px);
    top: 51px;
    width: 90%;
    margin-left: 6%;
    margin-top: 1%;
  }
  ._11-11-2024 {
    color: var(--text-color-1, #767676);
    text-align: left;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    line-height: 20.8px;
    font-weight: 400;
    width: 71px;
    height: 13px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 6%;
    margin-top: 4%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: 786px) {

    .rectangle-1015 {
        margin-left: 3%;
        margin-top: 5%;
        width: 95%;
      }

      .listen-compare-and-grow{
        margin-top: 3% !important;
        margin-left: 3% !important;
      }
      .lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-donec-non-neque-non-nibh-pretium-gravida-aliquam-consequat{
        margin-top: 3% !important;
        margin-left: 3% !important;
   
    }

    .rectangle-1014{
        margin-top: 8% !important;
        margin-left: 3% !important;
      }
      .rectangle-1016 {
        margin-left: 3%;
        margin-top: 5%;
        width: 95%;
        border-style: none;
      }
      .recent-projects{
        margin-left: 3% !important;
        margin-top: 1% !important;
        font-size: 18px !important;
      }
      .row_width{
        width:100%;
      }
      .frame-13 {
        margin-left: 3% !important;
      }
  }

