
html, body {
  height: 100%;
  margin: 0;
  background-color: #F2F3FF; /* Background color */
}
.project,
.project * {
  box-sizing: border-box;
}
.project {
  background: #f2f3ff;
  position: relative;
  min-height: 100%; /* Ensure the project fills at least the full viewport height */
}
.cta {
  position: absolute;
  inset: 0;
}
.group-443 {
  position: absolute;
  inset: 0;
}

.group-449 {
  position: absolute;
  inset: 0;
}
.frame-14 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 577px;
  top: 409px;
}
.lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit {
  color: var(--text-color-1, #767676);
  text-align: left;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  line-height: 22.5px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 123px);
  top: 51px;
  width: 242px;
  height: 41px;
}
.lorem-ipsum {
  color: #000000;
  text-align: left;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 500;
  position: absolute;
  left: calc(50% - 123px);
  top: 16px;
  width: 107px;
  height: 21px;
}
._01-01-0001 {
  color: var(--text-color-1, #767676);
  text-align: left;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  line-height: 20.8px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 123px);
  top: 110px;
  width: 89px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame-15 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 876px;
  top: 409px;
}
.frame-17 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 280px;
  top: 565px;
}
.frame-19 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 876px;
  top: 565px;
}
.frame-16 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 1175px;
  top: 409px;
}
.frame-18 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 579px;
  top: 565px;
}
.frame-20 {
  background: #fcfcff;
  border-radius: 6px;
  border-style: solid;
  border-color: #cdcdcd;
  border-width: 0.5px;
  width: 288px;
  height: 145px;
  position: absolute;
  left: 1175px;
  top: 565px;
}
