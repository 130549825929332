/* sidewindow.css */

.side-window {
  position: fixed;
  top: 0;
  right: -100%;
  width: 250px; /* Reduced width */
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.side-window.open {
  right: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.user-name {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}
.fa_check{
  margin-bottom: 4%;
}

.user-pic {

  background-color: #9E05BD;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #ccc;
  margin: 10px 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: color 0.2s ease;
  color: #555;
}

.menu-item:hover {
  color: #007bff;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.menu-item i, .menu-item img {
  width: 22px; /* Icon width */
  height: 22px; /* Icon height */
  margin-right: 2px; /* Space between icon and text */
  position: relative; /* Enable relative positioning */
  top: 3px; /* Move the icon 1px lower */
}
.menu-item span {
  font-family: 'Poppins', sans-serif; /* Set font-family to Poppins */
  font-size: 16px; /* Increase font size */
  font-weight: 400; /* Normal font weight */
  font-style: normal; /* Normal font style */
  line-height: 1.5; /* Adjust line height for better readability */
  color: var(--Text-color-1, #767676); /* Set text color */
  text-align: center; /* Center align the text */
}

.profile{
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin-bottom: 5%;
}

.drop_icon{
  margin-left: 5px;
}

.dropdown-container {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
  display: none;
}

.dropdown-container .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 8px 12px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: #333;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.drop_icon.open {
  transform: rotate(180deg);
}
