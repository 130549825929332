.button-primary-with-icon,
.button-primary-with-icon * {
  box-sizing: border-box;
}
.button-primary-with-icon {
  border-radius: 8px;
  background: var(--Color-primary, #4650A7);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 230px;
  position: relative;
  cursor: pointer;
}
.button-primary-with-icon .frame-1 {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.button-primary-with-icon .button-name {
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 164.706% */
}
