html, body {
  height: 100%;
  margin: 0;
  background-color: #F2F3FF; /* Background color */
  height: 100vh; /* Ensures it covers the full height */
}

.signup-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

/* Form container styling */
.signup-form {
  width: 90%;
  max-width: 600px;
  border-radius: 16px;
  border: 1px rgba(205, 198, 219, 0.20) solid;
  position: relative;
  background: linear-gradient(180deg, #FBFAFB 0%, white 100%);
  box-shadow: 0px 0px 30px rgba(205, 9, 198, 218.91, 0.30);
  padding: 25px;
  padding-left: 4%;
  padding-right: 4%;
  display: grid;
  grid-template-columns: 1fr; /* One column grid */
  gap: 20px; /* Space between elements */
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  max-height: 700px; /* Prevent form from growing too large */
  height: auto;
  overflow: auto;
  margin-bottom: 6%;
  margin-top: 14%; /* Set the margin-top to 10% of the viewport height */
}

/* Account Type Radio Buttons */
.account-type-radio {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
}

.account-type-radio label {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #212939;
  display: flex;
  align-items: center;
  display: inline-block;
}

.account-type-radio input {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* For Webkit browsers (Safari, Chrome) */
  -moz-appearance: none; /* For Firefox */
  width: 14px;  /* Reduced size */
  height: 14px; /* Reduced size */
  border-radius: 50%;
  border: 0.2px solid #000000; /* Black border */
  background-color: #FFFFFF; /* White fill color */
  margin-right: 3px;
  cursor: pointer;
  position: relative;
}

.account-type-radio input:checked {
  background-color: #FFFFFF; /* White background when selected */
  border-color: #332c2c; /* Border color when selected */
}

.account-type-radio input:checked::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px; /* Reduced dot size */
  height: 8px; /* Reduced dot size */
  border-radius: 50%;
  background-color: #4650A7; /* Blue dot when selected */
}

/* Media query for screen width 567px or less */
@media (max-width: 567px) {
  .account-type-radio label {
    margin-left: 0; /* Reset the left margin for small screens */
    margin-right: 0; /* Reset the right margin for small screens */
  }
}
@media (min-width: 768px) {
  .label_1 {
    margin-left: 15%; /* Set a custom left margin */
  }
  .label_2 {
    margin-right: 15%; /* Set a custom left margin */
  }

  .signup-form {
    margin-top: 8%;
  }
}

/* Form Fields */
.signup-form-fields {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 16px; /* Spacing between fields */
  width: 100%;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .signup-form-fields {
    grid-template-columns: 1fr; /* Single column for small screens */
  }
}

/* Individual form items */
.signup-form-item {
  display: flex;
  flex-direction: column;
}

/* Input fields */
.signup-input-field,
.signup-password-field {
  width: 100%;
  padding: 13px 16px;
  border-radius: 8px;
  border: 1px #E7E7E7 solid;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  opacity: 0.75;
  box-sizing: border-box;
}

/* Labels */
.signup-input-label,
.signup-password-label {
  display: block;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
}

/* Submit button */
.signup-submit-button {
  width: 100%;
  padding: 13px;
  background-color: #4650A7;
  color: white;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  border: none;
  cursor: pointer;
  grid-column: span 2; /* Span across two columns in large screens */
}

@media (max-width: 768px) {
  .signup-submit-button {
    grid-column: span 1; /* Full-width button in small screens */
  }
}

/* Already have an account and Create Account Link Grid */
.signup-login-link-container {
  display: flex; /* Flexbox ensures everything stays in a line */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Vertically center the content */
  text-align: center; /* Ensure text inside the container is centered */
  margin-top: 10px;
  width: 100%; /* Adjust to ensure full width */
}

@media (min-width: 1465px) {
  .signup-login-link-container {
    width: 120%; /* Adjust width for larger screens */
  }
}

/* Apply margin-left: 50% for screens 768px or greater */
@media (min-width: 769px) {
  .signup-login-link-container {
    margin-left: 50%;
  }
}

.signup-login-link-container p {
  margin: 0; /* Remove default margin from the paragraph */
  color: #808080; /* Color for the text */
}

.signup-login-link {
  color: #4650A7; /* Color for the link */
  font-weight: 600;
  text-decoration: none;
}

.signup-login-link:hover {
  text-decoration: underline; /* Underline the link on hover */
}

/* Error message */
.signup-error {
  color: red;
  margin-top: 2%;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  width: 150%;
}
