/* Navbar Styles */
.nav {
    box-sizing: border-box;
    height: auto; /* Allow height to adjust based on content */
    padding: 1% 2%; /* Add padding for spacing */
    background: #fcfcff;
    border-style: solid;
    border-color: #cdcdcd;
    border-width: 1px;
    display: flex;
    flex-direction: column; /* To stack elements vertically */
    z-index: 1000; /* Ensure navbar is above other elements */
}

.rectangle-997 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out items evenly */
    padding-top: 0.5%;
    padding-left: 1%;
    padding-right: 1%;
    box-sizing: border-box;
}

.frame-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: auto; /* Let it adjust based on content */
}

.section2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px; /* Add spacing between elements */
    flex-wrap: nowrap; /* Prevent wrapping */
    width: auto; /* Shrink to fit content */
}
.group-2{
    cursor: pointer;
}
.group-15 {
    position: relative; /* Required for the dropdown positioning */
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.rgb-color-wheel {
    width: 25px; /* Set a fixed size for smaller screens */
    height: 25px;
    margin: 0; /* Remove any extra margin */
}

.english {
    color: #000000;
    font-family: "Inter-Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.vector-1 {
    width: 10px;
    height: 5px;
    cursor: pointer;
}

.component-2 {
    display: flex;
    align-items: center;
    gap: 16px; /* Add more space between Cooper Vaccaro and the next element */
}

.cooper-vaccaro {
    font-size: 16px;
    font-weight: 500;
}

.ellipse-12 {
    background: #9e05bd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.c {
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
}

/* Dropdown Styles */
.dropd-menu {
    position: absolute;
    top: 200%; /* Position directly below the parent */
    left: 0;
    border: 1px solid #cdcdcd;
    z-index: 9999;
    padding: 8px 0;
    display: block; /* Ensure visibility when rendered */
    padding: 60%;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
    width: 250px;
    flex-shrink: 0;
}

.dropdown-item {
    padding: 8px 16px;
    color: #000000;
    font-size: 14px;
    font-family: "Inter-Medium", sans-serif;
    cursor: pointer;
}

.flex_color {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 8px; /* Add space between text and box */
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.box1, .box2, .box3 {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 2px;
    margin: 0; /* Remove unnecessary margins */
    margin-left: auto; /* Align the box to the right */
}

.box1 {
    background: #0D8422;
}

.box2 {
    background: #e23a10;
}

.box3 {
    background: #D9A600;
}

.dropdown-item:hover {
    background: #f0f0f0;
}

/* Language Popup Styles */
.language-popup {
    position: absolute;
    top: 12%; /* Position below the "Vector 1" icon */
    right: 9%;
    background: #fff;
    border: 1px solid #cdcdcd;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    padding: 10px;
    width: 120px;
}

.language-option {
    padding: 8px 12px;
    cursor: pointer;
    font-family: "Inter-Medium", sans-serif;
    font-size: 14px;
    color: #000;
}

.language-option:hover {
    background: #f0f0f0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .rectangle-997 {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start;
        padding: 0.5% 2%;
    }

    .section2 {
        display: none; /* Hide section2 on smaller screens */
    }

    .english {
        font-size: 12px;
    }

    .cooper-vaccaro {
        font-size: 12px;
    }

    .rgb-color-wheel {
        width: 20px;
        height: 20px;
    }

    .ellipse-12 {
        width: 25px;
        height: 25px;
    }

    .c {
        font-size: 14px;
    }

    .dropd-menu {
        width: 100%; /* Adjust dropdown to fit smaller screens */
    }

    .dropdown-item {
        font-size: 12px; /* Smaller font size for smaller screens */
    }
}

.ellipse-popup {
    position: absolute;
    top: 13%; /* Position below the ellipse */
    right: 1%;
    background: #fff;
    border: 1px solid #cdcdcd;
    z-index: 9999;
    padding: 10px;
    width: 150px;

    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 192px;
    height: 128.624px;
    flex-shrink: 0;
}

.ellipse-option {
    padding: 8px 12px;
    cursor: pointer;

    color: var(--Text-color-1, #767676);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ellipse-option:hover {
    background: #f0f0f0;
}

.profile_icon{
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    margin-bottom: 1%;
    margin-right: 3%;
}

.navbar-toggler {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 1rem; /* Adjust the right alignment */
    top: 1rem; /* Adjust the top alignment if needed */
}

@media (max-width: 768px) {
    .navbar-toggler {
        display: block;
    }

    .section2 {
        display: none; /* Hide non-essential items in mobile view */
    }
    .nav {
        background-color:  #F2F3FF;
        border-style: none;
    }
}

.popup_svg{
    margin-right: 5%;
    margin-bottom: 1%;
}
