/* General styles */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.main-container {
  display: flex;
  height: 100vh;
}

/* Sidebar styles */
.sidebar_ {

  background: #fcfcff;
  border-right: 1px solid var(--stock, #cdcdcd);
  height: 100vh;
  width: 20%;
}

.rectangle-998 {
  background: #fcfcff;
  border-style: solid;
  border-color: var(--stock, #cdcdcd);
  border-width: 1px;
  width: 100%;
  height: 100vh;
  padding-top: 0.5%;
}

.rectangle-33 {
  background: linear-gradient(
    107.91deg,
    rgba(104, 117, 241, 1) 0%,
    rgba(70, 80, 167, 1) 100%
  );
  border-radius: 15.52px;
  width: 85%;
  height: 221px;
  margin-left: 5%;
  margin-top: 180%;
  padding: 5%;
}

.current-plan {
  color: #ffffff;
  text-align: left;
  font-family: "Poppins-Light", sans-serif;
  font-size: 12px;
  letter-spacing: 0.01em;
  font-weight: 300;
  width: 79px;
}

.plan-validity-end {
  color: #ffffff;
  text-align: left;
  font-family: "Poppins-Light", sans-serif;
  font-size: 12px;
  line-height: 21px;
  font-weight: 300;
  width: 105px;
}

.rectangle-34 {
  background: #ffffff;
  border-radius: 15.52px;
  height: 31.04px;
  box-shadow: 1.55px 3.1px 3.1px 0px rgba(79, 42, 234, 0.17);
  padding-top: 3%;
}

.upgrade-now {
  color: #4650a7;
  text-align: center;
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 10.86px;
  letter-spacing: 0.01em;
  font-weight: 600;
}

.daily-attempt-left-03 {
  color: #ffffff;
  text-align: left;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 12px;
  line-height: 34px;
  font-weight: 400;
  width: 129px;
  margin-top: -8px;
}

._10-11-2024 {
  color: #ffffff;
  text-align: left;
  font-family: "Inter-Medium", sans-serif;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
  width: 67px;
}

.free-plan {
  color: #ffffff;
  text-align: left;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 17px;
  line-height: 34px;
  font-weight: 500;
  width: 79px;
}

.rectangle-5 {
  background: #4650a7;
}

.menus{
  border-radius: 5px;
  display: flex;
  width: 88%;
  height: 48px;
  margin-left: 5%;
  padding: 3%;
  margin-bottom: 1%;
  margin-top: 4%;
}

.non_selected {
  border-radius: 5px;
  display: flex;
  width: 88%;
  height: 48px;
  padding: 4%;
  margin-left: 5%;
  margin-bottom: 3%;
}

.project2 {
  color: #ffffff;
  text-align: center;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 16px;
  line-height: 34px;
  font-weight: 400;
  margin-left: 3%;
}

.history {
  color: var(--text-color-1, #767676);
  text-align: center;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 16px;
  line-height: 34px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3%;
}


.frame {
  width: 17px;
  height: 17px;
}

.frame2{
  margin-top: 30%;
}

/* Content area styles */
.content {
  flex: 1; /* Take remaining space */
  padding: 20px;
  background-color: #f5f5f5;
  overflow-y: auto;
}

/* Adjustments for the layout */
.rectangle-998 {
  padding-top: 0.5%;
  height: 100%;
}

.rectangle-5,
.non_selected {
  margin-bottom: 1rem; /* Add space between items */
}

.current-plan,
.free-plan,
.daily-attempt-left-03,
.plan-validity-end,
._10-11-2024 {
  margin-bottom: 0.5rem; /* Consistent spacing */
}

/* Responsive design: Hide sidebar for small screens */
@media screen and (max-width: 768px) {
  .sidebar_ {
    display: none;
  }

  .content {
    flex: 1; /* Full width for content */
    padding: 10px; /* Reduced padding for smaller screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .sidebar_ {
      width: 70%; /* Increase sidebar width */
  }
  .rectangle-33 {
    margin-top: 90%;
  }
}

@media (min-width: 1024px) and (max-width: 1478px) {
  .sidebar_ {
      width: 45%; /* Increase sidebar width */
  }
  .rectangle-33 {
    margin-top: 70%;
  }
}